import React from "react";

const AdmissionInfo =()=>{
    return(
        <div className="">
            AdmissionInfo
        </div>
    )
}

export default AdmissionInfo;