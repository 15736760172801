import img1 from  "./gallery/pictures/gallery/img1.jpg";
import img2 from "./gallery/pictures/Dental.jpg";
import img3 from  "./gallery/pictures/gallery/img3.jpg";
import img4 from  "./gallery/pictures/gallery/img4.jpg";
import img5 from  "./gallery/pictures/gallery/img5.jpg";
import img6 from  "./gallery/pictures/gallery/img6.jpg";
import img7 from  "./gallery/pictures/gallery/img7.jpg";
import img8 from  "./gallery/pictures/gallery/img8.jpg";
import img9 from  "./gallery/pictures/gallery/img9.jpg";
import img10 from  "./gallery/pictures/gallery/img10.jpg";
import img15 from  "./gallery/pictures/gallery/img15.jpg";
import img16 from  "./gallery/pictures/gallery/img16.jpg";
import img11 from "./gallery/pictures/freefoodimg.jpg";
import img12 from "./gallery/pictures/healthcamp.png"

export const imagehp = [
    {
        src:img11,
        width : 800,
        height : 600,
        title: "pic 0"
    },
    {
        src:img1,
        width : 800,
        height : 600,
        title: "pic 1"
    },
    {
        src:img2,
        width : 800,
        height : 600,
        title: "pic 2"
    },
    {
        src:img3,
        width : 800,
        height : 600,
        title: "pic 3"
    },
    
    {
        src:img6,
        width : 800,
        height : 600,
        title: "pic 6"
    },
   
    {
        src:img8,
        width : 800,
        height : 600,
        title: "pic 8"
    },
    {
        src:img9,
        width : 800,
        height : 600,
        title: "pic 9"
    },
   
]

export const imagehc =[
    {
        src:img12,
        width : 800,
        height : 600,
        title: "pic 12"
    },
    {
        src:img4,
        width : 800,
        height : 600,
        title: "pic 4"
    },
    {
        src:img5,
        width : 800,
        height : 600,
        title: "pic 5"
    },
    {
        src:img7,
        width : 800,
        height : 600,
        title: "pic 7"
    },
    {
        src:img10,
        width : 800,
        height : 600,
        title: "pic 10"
    },
    {
        src:img15,
        width : 800,
        height : 600,
        title: "pic 15"
    },
    {
        src:img16,
        width : 800,
        height : 600,
        title: "pic 16"
    },
]