import React from "react";

const Director= ()=>{
    return(
        <div className="aboutUs">
            This is director message
        </div>
    )
}

export default Director;