import React from "react";

const  Holidays=()=>{
    return(
        <div className="">
            Holidays
        </div>
    )
}

export default Holidays;